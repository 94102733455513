<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
export default {
    name: 'App',
    data(){
        return {
            screen:false,
            deviceId:'',
        }
    },
    mounted() {
        this.device();
        window.onresize = () => {
            return (() => {
                this.device();
            })()
        };
    },
    methods:{
        device(){
            let deviceWidth = document.documentElement.clientWidth;
            if(deviceWidth > 540) deviceWidth = 540;
            document.documentElement.style.fontSize = deviceWidth / 7.5 + 'px';
        },
    }
}
</script>
<style lang="less">
html,body,#app {height: 100%; margin: 0; padding: 0; font-size: 14px; font-family: 'Avenir',Helvetica,Arial,sans-serif;}
</style>
