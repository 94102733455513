import Vue from 'vue';
import VueRouter from 'vue-router';
// import Cookies from "js-cookie";
const NoContent = () => import('@/views/NoContent/index');
const QuickLogon = () => import('@/views/QuickLogon/index.vue');
const QuickWiFi = () => import('@/views/QuickWiFi/index.vue');
const DownLoad = () => import('@/views/DownLoad/index.vue');
Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        name: '',
        component: QuickLogon,
    },
    {
        path: '/404',
        name: 'NoContent',
        component: NoContent,
    },
    {
        path: '/quickLogon',
        name: 'QuickLogon',
        component: QuickLogon,
    },
    {
        path: '/quickWiFi',
        name: 'QuickWiFi',
        component: QuickWiFi,
    },
    {
        path: '/downLoad',
        name: 'DownLoad',
        component: DownLoad,
    },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
    if (to.matched.length === 0) { //匹配前往的路由不存在
        from.name ? next({
            name: from.name
        }) : next('/404'); //判断此跳转路由的来源路由是否存在，存在的情况跳转到来源路由，否则跳转到404
    }else {
        next()
    }
});
export default router
